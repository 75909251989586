import React from "react"
import Layout from "../components/layout"
import "./index.css"
import { StaticImage } from "gatsby-plugin-image"

export default function Home() {
  return (
    <Layout>
      <StaticImage className="logo" src="../images/logo.png" alt="fullauto logo" />
      <p>Dobrodošli na prvi pravi hrvatski oglasnik rabljenih automobila, s puno zanimljivih i korisnih informacija. 
      Vidimo se uskoro, stranica je u izradi!</p>
    </Layout>
  );
}